// theme classes

// flex classes
.flex {
  @include flex_class;

  &__rwd {
    @include rwd-min(1025) {
      @include flex_class;
    }
  }

  &__mob {
    @include rwd-min(768) {
      @include flex_class;
    }
  }

  &_start {
    @include flex_class(start);

    &__rwd {
      @include rwd-min(1025) {
        @include flex_class(start);
      }
    }

    &__mob {
      @include rwd-min(768) {
        @include flex_class(start);
      }
    }
  }

  &_center {
    @include flex_class(center);

    &__rwd {
      @include rwd-min(1025) {
        @include flex_class(center);
      }
    }

    &__mob {
      @include rwd-min(768) {
        @include flex_class(center);
      }
    }
  }
}

// 12_column grid
@mixin flex_grid_styles {
  display: flex;
  margin: 0 calc(var(--grid-gap) / -2);
  & > * {
    margin: 0 calc(var(--grid-gap) / 2);
  }
}

.flex_grid {
  @include flex_grid_styles;

  &__rwd {
    @include rwd-min(1025) {
      @include flex_grid_styles;
    }
  }

  &__mob {
    @include rwd-min(768) {
      @include flex_grid_styles;
    }
  }
}

[class*='flex_grid'] {
  flex-wrap: wrap;
  &._nowrap {
    flex-wrap: nowrap;
  }
  &._align_center {
    align-items: center;
  }
  &._space_between {
    justify-content: space-between;
  }

  & > * {
    flex: 1;
    margin-bottom: calc(var(--grid-gap));

    @for $i from 1 through 12 {
      &._col_#{$i} {
        flex: 0 0 calc(100% / 12 *#{$i} - var(--grid-gap));
      }
    }

    @include rwd-range(1599, 1025) {
      @for $i from 1 through 12 {
        &._col_sd_#{$i} {
          flex: 0 0 calc(100% / 12 *#{$i} - var(--grid-gap));
        }
      }
    }

    @include rwd-max(1024) {
      @for $i from 1 through 12 {
        &._col_rwd_#{$i} {
          flex: 0 0 calc(100% / 12 *#{$i} - var(--grid-gap));
        }
      }
    }

    @include rwd-max(767) {
      @for $i from 1 through 12 {
        &._col_mob_#{$i} {
          flex: 0 0 calc(100% / 12 *#{$i} - var(--grid-gap));
        }
      }
    }

    @include rwd-min(1025) {
      @for $i from 1 through 4 {
        &._space_l_#{$i} {
          padding-left: calc(100% / 12 *#{$i});
        }
        &._space_r_#{$i} {
          padding-right: calc(100% / 12 *#{$i});
        }
      }
    }
  }
}

// useful classes
.bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.object_fit {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.last_no_spacing > *:last-child:not(.button):not(.full_frame):not(blockquote) {
  margin-bottom: 0;
  padding-bottom: 0;
}

.rwd_show {
  @include rwd-min(1025) {
    display: none !important;
  }
}
.rwd_hide {
  @include rwd-max(1024) {
    display: none !important;
  }
}

.mob_show {
  @include rwd-min(768) {
    display: none !important;
  }
}
.mob_hide {
  @include rwd-max(767) {
    display: none !important;
  }
}

.is_visually_hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  pointer-events: none;
}

// 12 column spacing
@include rwd-min(1025) {
  @for $i from 1 through 4 {
    .col_space_l_#{$i} {
      padding-left: calc((100% + var(--grid-gap)) / 12 *#{$i});
    }
    .col_space_r_#{$i} {
      padding-right: calc((100% + var(--grid-gap)) / 12 *#{$i});
    }
  }
}
