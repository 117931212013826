// typography

.button {
  @extend %ani;
  display: inline-block;
  vertical-align: middle;
  font: 400 11px/20px $ff;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $cl;
  padding: 4px 9px;
  border-radius: 4px;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px $cl;
  cursor: pointer;
  user-select: none;

  @include rwd-min(768) {
    font-size: 12px;
    padding: 5px 11px;
  }

  @include rwd-max(359) {
    font-size: 10px;
    padding: 3px 7px;
  }

  @include hover {
    color: #fff;
    background-color: $cl;
  }
}

small,
sub {
  display: inline-block;
  vertical-align: baseline;
  font-size: 13px;
}

.content h1 {
  font-size: 24px;
  margin-bottom: 4vh;
  @include rwd-min(1025) {
    font-size: 36px;
  }
}

.material-icons {
  font-size: inherit;
}

.icon_element {
  @extend %flex_center;
  position: absolute;
  top: 0;
  width: 48px;
  height: 48px;
  font-size: 20px;
  background-color: #fff;
  cursor: pointer;
  user-select: none;

  span {
    @extend %ani;
  }
}

.check_element {
  left: 0;
  border-radius: 16px 0 0 16px;

  span {
    color: $bg;
    width: 22px;
    height: 22px;
    line-height: 20px;
    padding: 1px;
    text-align: center;
    border-radius: 6px;
    background-color: $bg;
  }

  input[type='checkbox'] {
    @extend %is_visually_hidden;
  }

  @include hover {
    span {
      color: $cl2;
      background-color: $cl2;
    }
  }
}

.button_element {
  right: 0;
  border-radius: 0 16px 16px 0;
}

.custom_icon {
  font-size: 24px;
  margin-left: 4px;

  &__wrap {
    display: flex;
    align-items: center;
  }
}
