// root elements

html {
  font: 400 #{$fb}/ 1.5 $ff;
  box-sizing: border-box;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
}

body {
  color: $tx;
  background-color: #f9f9f9;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#main {
  flex: 1 0 auto;
  overflow: hidden;
}

.container {
  width: 1080px;
  max-width: calc(100% - 48px);
  margin: 0 auto;
  position: relative;

  &.is_smaller {
    width: 540px;
  }
}
