// mixins

/// strips the unit from a number
@function strip-units($value) {
  @return ($value / ($value * 0 + 1));
}
// convert pixels to rems -> for a relational value of 12px write rem(12)
// assumes $fb is the font-size of <html>
@function rem($pxval) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  $base: $fb;
  @if not unitless($base) {
    $base: strip-units($base);
  }
  @return ($pxval / $base) * 1rem;
}

@mixin rwd-max($px) {
  $min-width: #{$px}px;
  @media screen and (max-width: $min-width) {
    @content;
  }
}
@mixin rwd-min($px) {
  $min-width: #{$px}px;
  @media screen and (min-width: $min-width) {
    @content;
  }
}
@mixin rwd-range($px, $px2) {
  $max-width: #{$px}px;
  $min-width: #{$px2}px;
  @media screen and (max-width: $max-width) and (min-width: $min-width) {
    @content;
  }
}

@mixin hover {
  &:active {
    @content;
  }
  @include rwd-min(1025) {
    &:hover {
      @content;
    }
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin columns($count: 2, $gap: 0) {
  -webkit-column-count: $count;
  -moz-column-count: $count;
  column-count: $count;
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
  > * {
    break-inside: avoid;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
  }
}

@mixin gradient($angle: 90deg, $colors...) {
  background-image: -webkit-linear-gradient(90 - $angle + 360, $colors);
  background-image: linear-gradient($angle, $colors);
}

@mixin center($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin triangle($width: 12px, $height: 10px, $cl: #000, $direction: top) {
  position: absolute;
  content: '';
  height: 0;
  width: 0;

  @if $direction == top {
    border-top: 0;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-bottom: $height solid $cl;
    top: -$height;
    left: 50%;
    transform: translateX(-50%);
  } @else if $direction == right {
    border-right: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-left: $height solid $cl;
    right: -$height;
    top: 50%;
    transform: translateY(-50%);
  } @else if $direction == bottom {
    border-bottom: 0;
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-top: $height solid $cl;
    bottom: -$height;
    left: 50%;
    transform: translateX(-50%);
  } @else {
    border-left: 0;
    border-top: $width solid transparent;
    border-bottom: $width solid transparent;
    border-right: $height solid $cl;
    left: -$height;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin flex_class($type: default) {
  display: flex;

  @if ($type == start) {
    align-items: flex-start;
    justify-content: space-between;
  } @else if ($type == center) {
    align-items: center;
    justify-content: center;
  } @else {
    align-items: center;
    justify-content: space-between;
  }
}
