// reset

* {
  margin: 0;
  padding: 0;
  outline: 0 none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  height: 100%;
}

article,
aside,
figure,
footer,
header,
nav,
section {
  display: block;
}

strong,
b {
  font-weight: 700;
}

td {
  vertical-align: middle;
}

table {
  border-spacing: 0;
}

mark {
  background: none;
}

ul {
  list-style: none;
}

ol {
  list-style-position: inside;
}

img {
  height: auto;
  display: block;
  max-width: 100%;
}

a {
  @extend %ani;
  color: $cl;
  outline: 0 none;
  text-decoration: none;

  @include hover {
    outline: 0 none;
    text-decoration: none;
  }

  & > img {
    border: 0;
  }
}

button {
  @extend %ani;
  border: medium none;
  background: rgba(255, 255, 255, 0);
  cursor: pointer;
}

input:not([type='submit']):not([type='button']):not([type='radio']):not(
    [type='checkbox']
  ),
select,
textarea {
  @extend %ani;
  width: 100%;
  outline: 0 none;
  box-sizing: border-box;
  -webkit-appearance: none;

  &:-webkit-autofill {
    -webkit-text-fill-color: $tx !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
}

input[type='submit'],
input[type='button'] {
  width: auto;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
}

input,
textarea {
  @include placeholder {
    color: $tx;
    opacity: 1;
  }
}

// selection
::selection {
  color: #fff;
  background-color: $cl;
}

::-moz-selection {
  color: #fff;
  background-color: $cl;
}

// custom scrollbar - chrome
html::-webkit-scrollbar {
  width: 6px;

  @include rwd-min(1025) {
    width: 8px;
  }
}

::-webkit-scrollbar-thumb {
  background-color: $cl;
}

::-webkit-scrollbar-track {
  background-color: $bg;
}

::-webkit-scrollbar-thumb:hover {
  background-color: darken($cl, 5%);
}

::-webkit-scrollbar-thumb:active {
  background-color: darken($cl, 5%);
}

// custom scrollbar - firefox
:root {
  scrollbar-width: thin;
  scrollbar-color: $cl $bg;
}

// disable default focus
:focus {
  outline: 0 none;
}

// enable focus for tabbable elements
a[href],
area[href],
input:not([disabled]):not([type='hidden']),
select:not([disabled]),
textarea:not([disabled]),
button:not([disabled]),
iframe,
object,
embed,
[tabindex='0'],
[contenteditable],
audio[controls],
video[controls],
summary,
[tabindex^='0'],
[tabindex^='1'],
[tabindex^='2'],
[tabindex^='3'],
[tabindex^='4'],
[tabindex^='5'],
[tabindex^='6'],
[tabindex^='7'],
[tabindex^='8'],
[tabindex^='9'] {
  &:focus,
  &:focus-visible {
    transition: none;
    outline: 2px dashed $cl;
  }

  .is_using_mouse & {
    &:focus,
    &:focus-visible {
      @extend %ani;
      outline: 0 none;
    }
  }
}
