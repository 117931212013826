// todo-list

.todo_list {
  padding: 20px 15px;
  border-radius: 8px;
  background-color: $bg;
  box-shadow: 0 0 10px rgba($tx, 0.1);

  @include rwd-min(768) {
    padding: 20px;
  }

  &__wrapper {
    margin-bottom: 10vh;
  }

  &__form {
    display: flex;
    position: relative;

    &__check {
      span {
        font-size: 18px;
      }

      &.is_checked {
        span {
          color: #fff;
          background-color: $cl;
        }
      }

      &.is_hidden {
        pointer-events: none;

        span {
          opacity: 0;
        }
      }
    }

    &__input {
      flex: 1;

      input[type='text'] {
        font-weight: 700;
        color: rgba($tx, 0.9);
        padding: 0 48px;
        border-radius: 16px;

        @include placeholder {
          font-weight: 700;
          color: rgba($tx, 0.25);
        }
      }
    }

    &__submit {
      @include hover {
        span {
          color: rgb(57, 186, 55);
        }
      }
    }
  }

  &__filters {
    display: flex;
    margin: 20px -6px 15px;

    @include rwd-min(768) {
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  &__filter {
    display: flex;
    align-items: center;
    margin: 0 6px;

    @include rwd-min(768) {
      margin: 0 8px;
    }

    &.is_active {
      color: #fff;
      background-color: $cl;
    }

    span {
      min-width: 18px;
      margin-left: 1px;
      text-align: center;
    }
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
    position: relative;

    &.is_completed {
      .todo_list__item__check span {
        color: #fff;
        background-color: $cl;
      }
    }

    &__text {
      flex: 1;

      input[type='text'] {
        font-weight: 400;
        padding: 0 48px;
        border-radius: 16px;

        &[disabled] {
          color: $bg;
          background-color: #fff;
          text-decoration: line-through;
        }
      }
    }

    &__error {
      display: block;
      width: 100%;
      font-size: 12px;
      font-weight: 700;
      color: rgb(255, 50, 100);
      margin: 5px 0 0 48px;
    }

    &__delete {
      @include hover {
        span {
          color: rgb(255, 50, 100);
        }
      }
    }
  }

  &__actions {
    margin-top: 20px;

    &__count {
      font-size: 15px;
      line-height: 30px;
    }

    &__clear {
      display: inline-block;

      &.is_hidden {
        display: none;
      }
    }
  }

  input[type='text'] {
    height: 48px;
    font-size: 16px;
    font-family: $ff;
    letter-spacing: 0.01em;
    border: none;
  }
}
